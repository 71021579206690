import React from "react";

const SearchInput = ({ label, name, value, onChange, placeholder, icon }) => {
	return (
		<div className="">
			<label
				htmlFor={name}
				className="block mb-2 font-medium">
				{label}
			</label>
			<div className="flex items-center relative">
				<div className="absolute text-gray-400 left-3 top-5 transform -translate-y-1/2 z-10">
					{icon}
				</div>
				<input
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					type="text"
					name={name}
					id={name}
					className="pl-8 bg-[#dcdffa31] border border-gray-400 sm: rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
				/>
			</div>
		</div>
	);
};

export default SearchInput;
