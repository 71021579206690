import React, { useState } from 'react';
import Avatar from './avatar';
import { FaCaretDown } from 'react-icons/fa';
import ToggleButton from './toggle_button';
import { updateAvailability } from '../utils/api_call/api_calls';
import { useAuth } from '../utils/api_call/auth_context';

const Header = ({ currentUser }) => {
	// const [availability, setAvailability] = useState("");
	const { refreshUser, logoutUser, accessToken } = useAuth();
	const [openUser, setOpenUser] = useState(false);

	const handleOpenUser = () => {
		setOpenUser(!openUser);
	};

	const handleToggle = async (newToggleState) => {
		if (currentUser?.status !== 'Assigned') {
			if (!newToggleState) {
				// setAvailability("Unavailable");
				const response = await updateAvailability(
					currentUser,
					'Unavailable',
					accessToken,
				);
				console.log(response);
			}

			if (newToggleState) {
				// setAvailability("Available");
				const response = await updateAvailability(
					currentUser,
					'Available',
					accessToken,
				);
				console.log(response);
			}

			await refreshUser();
		}
	};
	return (
		<div className='flex bg-white p-8 h-[80px] items-center space-x-8'>
			<div className='relative flex justify-between items-center w-full'>
				<div className='flex items-center space-x-3 rounded-lg cursor-pointer'>
					<div className='w-[32px]'>
						<Avatar name={currentUser.name} />
					</div>
					<div
						className='space-x-4 flex items-center justify-between w-full'
						onClick={handleOpenUser}>
						<div className=''>
							<p className='leading-tight font-semibold'>
								{currentUser.name}
							</p>
							<p className='leading-tight text-sm font-base text-gray-400'>
								{currentUser.email}
							</p>
						</div>
						<FaCaretDown />
					</div>
				</div>

				<div className='flex items-center space-x-6'>
					<div>
						{currentUser?.status === 'Available' && (
							<div className='bg-[#73e5bf] text-black py-1.5 px-2.5 rounded-md'>
								{' '}
								Online
							</div>
						)}
						{currentUser?.status === 'Unavailable' && (
							<div className='bg-gray-400 text-white py-1.5 px-2.5 rounded-md'>
								{' '}
								Offline
							</div>
						)}
						{currentUser?.status === 'Assigned' && (
							<div className='bg-[#73e5bf] text-gray-700 py-1.5 px-2.5 rounded-md'>
								{' '}
								Assigned
							</div>
						)}
					</div>
					<ToggleButton
						onToggle={handleToggle}
						assigned={currentUser?.status}
					/>
				</div>

				{openUser && (
					<div
						className='p-3 cursor-pointer absolute bg-white border w-fit mt-2 text-sm rounded-md'
						onClick={logoutUser}>
						Logout
					</div>
				)}
			</div>
		</div>
	);
};

export default Header;
