import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from './api_helper';
import { setCookie, getCookie } from '../local_storage'; // Use getCookie for consistency

const AuthContext = createContext();

const loadUserFromLocalStorage = () => {
	return JSON.parse(localStorage.getItem('courier')) || null; // Simplified
};
const loadToken = () => {
	return JSON.parse(localStorage.getItem('jwt')) || null;
};
const saveUserToLocalStorage = (user) => {
	localStorage.setItem('courier', JSON.stringify(user));
};
const saveToken = (token) => {
	console.log('Save Token: ', token);
	localStorage.setItem('jwt', JSON.stringify(token));
};
export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(loadUserFromLocalStorage());
	const [accessToken, setAccessToken] = useState(loadToken() || '');

	const navigate = useNavigate();

	const refreshUser = async () => {
		try {
			console.log('Refreshing Courier...');

			const response = await api.get(`/courier/${currentUser._id}`, {
				withCredentials: true,
				headers: {
					jwt: accessToken,
				},
			});
			const userDataFromServer = response.data.data;
			console.log('User data refreshed: ', response);
			// Update localStorage and state with the latest user data
			saveUserToLocalStorage(userDataFromServer);
			setCurrentUser(userDataFromServer);
		} catch (error) {
			console.error('Error fetching user data:', error);
		}
	};

	const handleAuthSuccess = async (response) => {
		if (response.status === 200) {
			const jwtToken = getCookie('jwt') || response.data.token;
			await setCookie('jwt', jwtToken);
			await setCurrentUser(response.data.data);
			saveUserToLocalStorage(response.data.data);
			await setAccessToken(response.data.data.accessToken);
			saveToken(response.data.data.accessToken);
			return response.data;
		} else {
			throw new Error(response.data.message);
		}
	};

	const signupcourier = async (name, phone_number, email, password) => {
		try {
			const response = await api.post(
				'/courier/signup',
				{
					name,
					phone_number,
					email,
					password,
				},
				{ withCredentials: true },
			);
			console.log(response);
			return handleAuthSuccess(response);
		} catch (error) {
			console.error('Signup error:', error.response.data.message);
			throw error;
		}
	};

	const loginCourier = async (phone, password) => {
		try {
			const response = await api.post(
				'/courier/login',
				{ phone, password },
				{ withCredentials: true },
			);
			console.log(response);
			return handleAuthSuccess(response);
		} catch (error) {
			console.error('Login error:', error);
			throw error;
		}
	};

	const logoutUser = () => {
		setCurrentUser(null);
		localStorage.removeItem('user');
		localStorage.removeItem('jwt');
		navigate('/signin');
	};

	const value = {
		currentUser,
		signupcourier,
		loginCourier,
		logoutUser,
		refreshUser,
		accessToken,
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};
