import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Toaster, toast } from 'sonner';
import { useAuth } from '../utils/api_call/auth_context';

const SignIn = () => {
	const navigate = useNavigate();
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const { loginCourier } = useAuth();
	const [signinLoading, setSignInLoading] = useState(false);
	const signInActive = phone && password;

	const handleSubmit = async () => {
		setSignInLoading(true);
		try {
			const response = await loginCourier(phone, password);
			// Handle successful login
			if (response.status === 200) {
				navigate('/');
				setSignInLoading(false);
				toast.success(response.message);
			}
		} catch (error) {
			// Handle login error
			console.error('Login error:', error);
			setSignInLoading(false);
			toast.error(error.response.data.message);
		}
	};

	return (
		<div className='w-full flex items-center justify-center h-screen bg-gradient-to-tr from-[rgba(135,250,215,0.66)] via-[rgba(181,89,235,0.43)] to-[rgba(245,242,255,0.38)]'>
			<div className='w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0'>
				<div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
					<h1 className='text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl'>
						Sign in to your account
					</h1>
					<form
						className='space-y-4 md:space-y-6'
						action='#'>
						<div>
							<label
								htmlFor='phone'
								className='block mb-2 text-sm font-medium text-gray-400'>
								Phone Number
							</label>
							<input
								placeholder='08100000000'
								value={phone}
								required={true}
								onChange={(e) => setPhone(e.target.value)}
								type='tel'
								name='phone'
								id='phone'
								className='bg-gray-50 border border-gray-400 text-gray-400 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
							/>
						</div>
						<div>
							<label
								htmlFor='password'
								className='block mb-2 text-sm font-medium text-gray-400'>
								Password
							</label>
							<input
								type='password'
								name='password'
								id='password'
								placeholder='Password'
								value={password}
								required={true}
								onChange={(e) => setPassword(e.target.value)}
								className='bg-gray-50 border border-gray-400 text-gray-400 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
							/>
						</div>
						<div className='flex items-center justify-between'>
							<div className='flex items-start'>
								<div className='flex items-center h-5'>
									<input
										id='remember'
										aria-describedby='remember'
										type='checkbox'
										className='w-4 h-4 border border-gray-400 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300'
										required=''
									/>
								</div>
								<div className='ml-3 text-sm'>
									<label
										htmlFor='remember'
										className='text-gray-400'>
										Remember me
									</label>
								</div>
							</div>
							<div
								href='#'
								className='text-sm font-medium text-primary-600'>
								Forgot password?
							</div>
						</div>
						<button
							onClick={handleSubmit}
							type='button'
							className={`h-[48px] ${
								signInActive
									? 'bg-[#73e5bf]'
									: 'bg-gray-100 text-gray-400'
							} w-full focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
							{signinLoading ? 'Loading...' : 'Sign in'}
						</button>
						<p className='text-sm font-light text-gray-400'>
							Don’t have an account yet?{' '}
							<span
								onClick={() => navigate('/create-account')}
								className='font-medium text-primary-600 cursor-pointer'>
								Sign up
							</span>
						</p>
					</form>
				</div>
			</div>
			<Toaster
				position='top-right'
				richColors
			/>
		</div>
	);
};

export default SignIn;
