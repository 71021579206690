import React, { useCallback, useEffect, useState } from 'react';
import Header from '../components/header';
import ActiveOrder from '../components/active_order';
import {
	FaArrowRight,
	FaChevronRight,
	FaFile,
	FaLocationArrow,
	FaPhoneAlt,
	FaSearchLocation,
} from 'react-icons/fa';
import { Drawer, Modal } from 'antd';
import {
	addAccount,
	getBanksList,
	getCurrentUserLocation,
	searchAddresses,
	updateLocation,
	updateOrderStatus,
} from '../utils/api_call/api_calls';
import { debounce } from '../utils/debounce';
import SearchInput from '../components/search';
import { FaLocationDot } from 'react-icons/fa6';
import { Toaster, toast } from 'sonner';
import { IoLocateOutline } from 'react-icons/io5';
import PinField from 'react-pin-field';
import Lottie from 'lottie-react';
import animationData from '../assets/Animation - 1712829832349.json';

const Main = ({ currentUser, accessToken, refreshUser, ws, useDrawer }) => {
	const toDoList = [
		{
			icon: <FaFile />,
			title: 'Connect your settlement account',
			subtitle: 'Receive your earnings directly to your bank account',
			buttonText: 'Connect Account',
			route: '',
			completed:
				currentUser?.settlement_account.account_number &&
				currentUser?.settlement_account.bank_code,
			openModal: 'account',
		},
		{
			icon: <FaFile />,
			title: 'Verify your identity',
			subtitle:
				'Let us know you, Verify your identity for a smoother courier experience',
			buttonText: 'Update your Info',
			route: '',
			completed: currentUser?.home_address,
			openModal: 'address',
		},
	];

	const [openModal, setOpenModal] = useState(false);
	const [modalView, setModalView] = useState('');
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [bankList, setBankList] = useState([]);
	const [filteredBanks, setFilteredBanks] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [account, setAccount] = useState('');
	const [bankCode, setBankCode] = useState('');
	const [searchView, setSearchView] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState('');
	const [address, setAddress] = useState(
		currentUser?.location?.address || '',
	);
	const [formattedPlace, setSelectedFormattedPlace] = useState('');
	const [gottenLocation, setGottenLocation] = useState('');
	const [gottenFormattedAddress, setGottenFormattedAddress] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);
	const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
	const addressActive = selectedLocation;
	const [status, setStatus] = useState(
		currentUser?.assigned_order?.currentStatus || null,
	);
	const [PIN, setPIN] = useState('');
	const [openPINModal, setOpenPINModal] = useState(false);
	const [modalData, setModalData] = useState('');

	const handleSetOrderAsDelivered = async (order) => {
		try {
			const orderStatus = 'Delivered';

			const response = await updateOrderStatus(
				order._id,
				orderStatus,
				PIN,
				accessToken,
			);
			if (response.status === 200) {
				refreshUser();
				console.log('At current status: ', response);
				toast('Order Delivered');
				setStatus(response.data.currentStatus);
				setOpenPINModal(false);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const formatPriceWithCommas = (price) => {
		return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	const fetchBankList = async () => {
		try {
			const response = await getBanksList();
			const data = await response;
			console.log('At Response', data);
			setBankList(data.data.banks.data);
			setFilteredBanks(data);
		} catch (error) {
			console.error('Error fetching bank list:', error);
		}
	};
	const handleBankSelection = (selectedBank) => {
		setSearchTerm(selectedBank.name);
		setDropdownOpen(false);
	};
	const handleOpenDropDown = async () => {
		setDropdownOpen(true);
		fetchBankList();
	};
	const handleOk = () => {
		setOpenModal(false);
	};
	const handleCancel = () => {
		setOpenModal(false);
	};
	const handleOpenModal = (view) => {
		setOpenModal(true);
		setModalView(view);
	};
	const handleEventType = (eventType, payload) => {
		switch (eventType) {
			case 'picked':
				handlePickedEvent(eventType, payload);
				break;
			case 'chargeSuccess':
				handleChargeSuccessEvent(eventType, payload);
				break;
			case 'assigned':
				handleAssignedEvent(eventType, payload);
				break;
			case 'delivered':
				handleDeliveredEvent(eventType, payload);
				break;
			case 'return':
				handleReturnEvent(eventType, payload);
				break;
			case 'ready':
				handleReadyEvent(eventType, payload);
				break;
			default:
				console.log(`Unhandled event type: ${eventType}`);
		}
	};

	const handlePickedEvent = (eventType, payload) => {
		// Handle "picked" event
		console.log(`Received ${eventType} event:`, payload);
		refreshUser();
	};

	const handleChargeSuccessEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		refreshUser();
	};
	const handleAssignedEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		refreshUser();
	};

	const handleDeliveredEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		refreshUser();
	};
	const handleReturnEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		refreshUser();
	};
	const handleReadyEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		refreshUser();
	};

	useEffect(() => {
		refreshUser();
	}, []);
	// API Calls
	// Account
	const handleAddAccount = async (bank, accountNumber) => {
		try {
			const response = await addAccount(
				currentUser,
				bank,
				accountNumber,
				accessToken,
			);
			if (response?.status === 200) {
				setOpenModal(false);
				setModalView('');
				refreshUser();
				toast(response.data.message);
			}
		} catch (error) {
			console.error(error);
		}
	};

	// Location
	const handleUpdateLocation = async () => {
		setLocationLoading(true);
		try {
			const response = await updateLocation(
				currentUser,
				selectedLocation,
				accessToken,
			);
			console.log('At main update location: ', response);
			if (response.status === 200) {
				setOpenModal(false);
				setModalView('');
				toast.success('Address Updated Successfully');
				setSelectedLocation('');
				setAddress('');
				refreshUser();
				setLocationLoading(false);
			}
		} catch (error) {
			console.error('Error updating location:', error.message);
			setLocationLoading(false);
			toast.error(error.response.data.message);
		}
	};

	const handleSetSelectedLocation = (location, formatted) => {
		setSelectedLocation(location);
		setSelectedFormattedPlace(formatted);
	};

	const debouncedSearch = useCallback(
		debounce(async (searchValue) => {
			try {
				const response = await searchAddresses(searchValue);
				setSearchResults(response || []);
			} catch (error) {
				console.error('Error:', error.message);
			} finally {
			}
		}, 1500),
		[debounce],
	);
	const GetLocationService = async () => {
		if (!navigator.onLine) {
			console.error('No internet connection available');
			// Display a message to the user about the network issue
		}
		function handleGeolocationError(error) {
			// Handle the error based on the code
			switch (error.code) {
				case error.PERMISSION_DENIED:
					console.error('User denied location access');
					// Display a message to the user explaining why location is unavailable
					break;
				case error.POSITION_UNAVAILABLE:
					console.error('Location information is unavailable');
					// Display a message to the user explaining the issue
					break;
				case error.TIMEOUT:
					console.error('Geolocation timed out');
					// Display a message to the user explaining the delay
					break;
				default:
					console.error('Geolocation error:', error.message);
				// Handle any other errors
			}
		}
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const { latitude, longitude } = position.coords;
					try {
						const response = await getCurrentUserLocation(
							latitude,
							longitude,
						);
						if (response.status === 200) {
							// Resolve with location data (consider modifying if needed)
							resolve(response.data || position.coords);
						} else {
							reject(new Error('Error fetching location data'));
						}
					} catch (error) {
						console.error(
							'Error in getCurrentUserLocation:',
							error.message,
						);
						handleGeolocationError(error);
					}
				},
				(error) => {
					console.error('Error getting location:', error.message);
					console.log(error);
					handleGeolocationError(error);
				},
				{ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
			);
		});
	};
	const locationGetter = async () => {
		const result = await GetLocationService();
		console.log('Location Result', result);
		setGottenLocation(result.address.formatted_address);
		setGottenFormattedAddress(result.address.place_name);
	};

	//Build Components
	const connectSettlementAccount = () => {
		return (
			<div className='mt-4 space-y-8 '>
				<div className='space-y-4 '>
					<div className=''>
						<label
							htmlFor='product'
							className='block mb-2  font-medium'>
							Bank
						</label>
						<div className='relative w-full mr-4 md:-mr-3 mb-3 text-left duration-300 ease-in-out '>
							<div className='relative flex'>
								<input
									type='text'
									className='inline-flex [#dcdffa31] border border-gray-400 sm: rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5'
									placeholder='Enter Bank Name'
									onClick={handleOpenDropDown}
									onChange={(e) =>
										setSearchTerm(e.target.value)
									}
									value={searchTerm}
								/>
							</div>
							{dropdownOpen && (
								<div
									className='absolute cursor-pointer overflow-y-scroll border border-gray-400 no-scrollbar max-h-[36rem] z-20 w-full py-4 mt-2 space-y-2 bg-white rounded-md shadow-xs shadow-lg p-3'
									onClick={() => {}}>
									{Array.isArray(filteredBanks) &&
										filteredBanks.map((bank) => (
											<div
												key={bank.id}
												className='option'
												onClick={() => {
													handleBankSelection(bank);
													setBankCode(bank.code);
												}}>
												<div className='p-3 bg-gray-100'>
													{bank.name}
												</div>
											</div>
										))}
									{/* {filteredBanks.length === 0 && (
										<div className="option">
											No banks found, please try again
										</div>
									)} */}
								</div>
							)}
						</div>
					</div>
					<div className=''>
						<label
							htmlFor='account'
							className='block mb-2  font-medium'>
							Account Number
						</label>
						<input
							placeholder='0000000000'
							value={account}
							required={true}
							onChange={(e) => setAccount(e.target.value)}
							type='number'
							name='account'
							id='account'
							className='bg-[#dcdffa31] border border-gray-400 sm: rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
						/>
					</div>
				</div>
				<div className='place-content-end flex space-x-3'>
					<div
						className='h-[48px] w-fit border border-gray-200 bg-[#f7f7f7] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg  px-5 py-2.5 text-center cursor-pointer flex items-center justify-center space-x-2'
						onClick={handleCancel}>
						<p>Cancel</p>
					</div>
					<div
						className='h-[48px] w-fit bg-[#73e5bf] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg  px-5 py-2.5 text-center cursor-pointer flex items-center justify-center space-x-2'
						onClick={() => handleAddAccount(bankCode, account)}>
						<p>Add Account</p>
					</div>
				</div>
			</div>
		);
	};
	const addAddressComponent = () => {
		return (
			<>
				<div className='relative h-full'>
					<div className='space-y-5'>
						<div className='space-y-3 relative'>
							{/* Address Component */}
							<SearchInput
								label='Set Address'
								name='search'
								value={address}
								onChange={(e) => {
									setAddress(e.target.value);
									setSearchView(true);
								}}
								placeholder='Search for your address'
								icon={<FaSearchLocation />}
							/>

							{/* If the user uses their location, select the address and update the selected variable */}
							{!searchView && (
								<>
									<div
										className='bg-gray-100 p-3 rounded-lg flex space-x-3 items-center cursor-pointer'
										onClick={() => {
											handleSetSelectedLocation(
												gottenLocation,
												gottenFormattedAddress,
											);
										}}>
										<FaLocationArrow />{' '}
										<div>
											<p className='font-medium'>
												Use my Current Location
											</p>
											<p className='text-sm text-gray-500'>
												{gottenLocation}
											</p>
										</div>
									</div>
									<div className='border-b'></div>
								</>
							)}

							{/* Show selected address */}
							{selectedLocation && !searchView && (
								<>
									<div className='space-y-1.5'>
										<p className='text-sm text-gray-500'>
											Selected Location
										</p>
										<div className='bg-gray-100 w-full p-3 cursor-pointer rounded-md'>
											<div className='flex items-center space-x-3'>
												<FaLocationDot />
												<div>
													<div className='font-medium'>
														{selectedLocation}
													</div>
													<div className='text-sm text-gray-500'>
														{formattedPlace}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='bg-white w-full mt-3'>
										<div
											className={`h-[48px] w-full ${
												addressActive
													? 'bg-[#73e5bf] text-gray-800'
													: 'bg-gray-100 text-gray-400'
											} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg  px-5 text-center cursor-pointer flex items-center justify-center space-x-2`}
											onClick={() => {
												addressActive &&
													handleUpdateLocation();
											}}>
											<div className='w-[75%]'>
												<p className='truncate'>
													{locationLoading
														? 'Loading...'
														: `Use ${selectedLocation}`}
												</p>
											</div>
										</div>
									</div>
								</>
							)}

							{/* Use the search input to find addresses */}
							{/* Select the address and update set selected location variable,  */}
							{searchView && (
								<div className=' bg-white w-full'>
									<div className='space-y-2 max-h-[32rem] overflow-y-scroll no-scrollbar'>
										{searchResults?.map((result, index) => (
											<div
												className='px-3 cursor-pointer rounded-md'
												key={index}
												onClick={() => {
													handleSetSelectedLocation(
														result.place_name,
														result?.formatted_address,
													);
													setSearchView(false);
												}}>
												<div className='flex items-center space-x-3'>
													<IoLocateOutline
														size={21}
													/>
													<div className='border-b w-full py-3'>
														<div className='font-medium'>
															{result.place_name}
														</div>
														<div className='text-sm text-gray-600 '>
															{
																result.formatted_address
															}
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					</div>
					{/* <div className="bg-white w-full  p-5 ">
				<div
					className={`h-[48px] w-fit ${addressActive
						? "bg-[#73e5bf] text-gray-800"
						: "bg-gray-200 text-gray-400"
						} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg  px-5 text-center cursor-pointer flex items-center justify-center space-x-2`}
					onClick={() => {
						// addressActive &&
						// 	setActiveStage("review");
					}}>
					<p>Proceed to review</p>
					<FaArrowRight size={12} />
				</div>
			</div> */}
				</div>
			</>
		);
	};

	//use effect
	useEffect(() => {
		const filtered = bankList.filter((bank) =>
			bank.name.toLowerCase().includes(searchTerm.toLowerCase()),
		);
		setFilteredBanks(filtered);
	}, [searchTerm, bankList]);

	useEffect(() => {
		locationGetter();
	}, []);

	useEffect(() => {
		if (address.trim() !== '') {
			debouncedSearch(address);
		} else {
			setSearchResults([]);
		}

		return () => debouncedSearch.cancel();
	}, [address, debouncedSearch]);

	useEffect(() => {
		if (!ws) return;

		// Event listener for incoming WebSocket messages
		ws.onmessage = (event) => {
			const eventData = JSON.parse(event.data);
			const eventType = eventData.event;
			console.log(eventType);
			const payload = eventData.payload;
			console.log(payload);
			handleEventType(eventType, payload);
		};
		// Cleanup function
		return () => {
			ws.onmessage = null;
		};
	}, [ws]);
	const profileUpdated =
		currentUser?.settlement_account?.account_number &&
		currentUser?.settlement_account?.bank_code &&
		currentUser?.home_address;

	const totalDeliveredOrders =
		// Get the length of filtered orders array
		currentUser?.orders
			// Filter orders with currentStatus as "Delivered" and completed as true
			?.filter(
				(order) =>
					order.currentStatus === 'Delivered' && order.completed,
			)?.length || 0;
	const headers = ['Order', 'Amount'];

	const handleViewOrderHistory = () => {
		setOpenHistoryDrawer(true);
	};

	const EarningHistory = () => {
		return (
			<div className='flex md:space-x-5 mt-4'>
				<div className='md:bg-white w-full md:border md:border-gray-100 rounded-xl md:p-3'>
					<div className='md:flex hidden border-b justify-between items-center p-3.5'>
						<div className='font-medium text-md'>
							Earning History
						</div>
						<div className='w-fit flex items-center cursor-pointer bg-[#dcdffa31] border border-gray-200 sm: rounded-full focus:ring-primary-600 focus:border-primary-600 px-3 py-1 space-x-1 '>
							<p>view all</p> <FaChevronRight size={12} />
						</div>
					</div>
					{/* Table */}
					<div className='w-full '>
						<table className='w-full'>
							<thead className='border-b'>
								<tr className='text-gray-700'>
									{headers.map((header, index) => (
										<th
											className='text-left md:p-3 pb-3 md:pb-0'
											key={index}>
											{header}
										</th>
									))}
								</tr>
							</thead>
							<tbody className=''>
								{currentUser?.earnings?.history.map(
									(e, index) => (
										<tr
											key={`${index}`}
											className='border-b hover:bg-gray-50 cursor-pointer text-gray-500 whitespace-nowrap'>
											<td className='items-center flex w-full md:p-3 py-3 md:py-0 mt-2'>
												<div className='bg-gray-50 py-1.5 px-3.5 rounded-md flex text-gray-400 items-center place-content-center font-medium'>
													{e.order}
												</div>
											</td>
											<td className='p-3'>{e.amount}</td>
										</tr>
									),
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className='bg-[#f8f8f8] h-screen overflow-y-scroll '>
			<Header currentUser={currentUser} />
			<Toaster />
			<section className=' p-4 md:p-8 space-y-4'>
				{!profileUpdated && (
					<div className='bg-white p-4 rounded-md'>
						<div className='text-gray-500'>
							Update your profile to start accepting orders
						</div>
						<div className='md:flex space-y-4 md:space-y-0 md:space-x-4 mt-4'>
							{toDoList.map((e, index) => {
								return (
									!e.completed && (
										<div
											key={index}
											className='space-y-8 border-gray-100 border p-4 rounded-lg w-full lg:w-1/3 flex flex-col justify-between'
											onClick={() => {
												handleOpenModal(e.openModal);
											}}>
											<div className=' flex-col space-y-8 w-full'>
												<div className='text-[#1d5f49]'>
													{e.icon}
												</div>
												<div>
													<p className='text-[18px] font-medium'>
														{e.title}
													</p>
													<p className='mt-2 text-gray-400'>
														{e.subtitle}
													</p>
												</div>
											</div>
											<div className='whitespace-nowrap h-[48px] flex w-full text-center cursor-pointer items-center justify-center space-x-2 mt-4 border border-gray-200 bg-[#f7f7f7] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg  px-5 py-2.5'>
												{/* {e.icon} */}
												<p>{e.buttonText}</p>
											</div>
										</div>
									)
								);
							})}
						</div>
					</div>
				)}
				<div className='md:space-x-4 md:grid grid-cols-12'>
					<div className='w-full col-span-8'>
						<div className='bg-gray-900 rounded-lg  p-8 h-fit'>
							<p className='text-gray-300'>Overview</p>
							<div className='grid grid-cols-2 text-[16px] mt-4'>
								<div className='space-y-1 '>
									<p className='text-[13px] text-gray-400'>
										Total Earnings
									</p>
									<p className='font-medium text-3xl text-white'>
										{/* {formatPriceWithCommas(totalSalesValue)} */}
										{formatPriceWithCommas(
											currentUser?.earnings?.total,
										)}
									</p>
								</div>
								<div className='space-y-1 '>
									<p className='text-[13px] text-gray-400'>
										Total Deliveries
									</p>
									<p className='font-medium text-3xl text-white'>
										{/* {formatPriceWithCommas(totalSalesValue)} */}
										{totalDeliveredOrders}
									</p>
								</div>
							</div>
						</div>
						{/* Desktop View  */}

						{useDrawer ? (
							<div
								onClick={handleViewOrderHistory}
								className='p-4 bg-[#73e5bf]/30 text-[#124b38] font-semibold justify-between items-center rounded-md w-full my-4 flex'>
								<p>View earning history</p>
								<FaArrowRight />
							</div>
						) : (
							<EarningHistory />
						)}
					</div>

					{/* Active Order */}
					<div className='col-span-4'>
						<div>
							<div>
								<ActiveOrder
									currentUser={currentUser}
									accessToken={accessToken}
									refreshUser={refreshUser}
								/>
							</div>
							{openPINModal && (
								<Modal
									open={openPINModal}
									className='rounded-t-xl'
									height='90%'
									onCancel={() => {
										setOpenPINModal(false);
									}}
									footer={false}
									closable={true}
									width={288}
									title={
										<div className='text-[24px]'>
											Input PIN
										</div>
									}>
									<div>
										<div className='flex space-x-4 items-center my-8'>
											<PinField
												length={4}
												onChange={(code) =>
													setPIN(code)
												}
												className='h-[48px] text-center mx-auto w-[48px] flex border border-gray-300 rounded overflow-hidden'
											/>
										</div>
										<button
											className='h-[48px] text-sm w-full bg-[#73e5bf] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg  px-5 py-2.5 text-center cursor-pointer flex items-center justify-center space-x-2'
											onClick={() => {
												handleSetOrderAsDelivered(
													modalData,
												);
											}}>
											<p>Complete delivery</p>
										</button>
									</div>
								</Modal>
							)}
						</div>
					</div>
				</div>
			</section>

			{openModal && (
				<Modal
					className='rounded-t-xl'
					height='90%'
					open={openModal}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={false}
					closable={true}
					title={
						<div className='text-[24px]'>
							{modalView === 'account' && 'Add your Bank'}
							{modalView === 'address' && 'Add your Location'}
						</div>
					}>
					{modalView === 'account' && connectSettlementAccount()}
					{modalView === 'address' && addAddressComponent()}
				</Modal>
			)}

			{openHistoryDrawer && (
				<Drawer
					placement='bottom'
					onClose={() => setOpenHistoryDrawer(false)}
					open={openHistoryDrawer}
					closable={false}
					height='60vh'
					bodyStyle={{ padding: '0', paddingTop: '12px' }}
					headerStyle={{
						padding: '0',
						paddingTop: '8px',
						paddingBottom: '12px',
					}}
					className='rounded-t-xl p-4'
					title={<div className=''>Earning History</div>}>
					<EarningHistory />
				</Drawer>
			)}
		</div>
	);
};

export default Main;
