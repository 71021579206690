import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/api_call/auth_context";

const ProtectedRoutes = ({ children }) => {
	const { currentUser } = useAuth();

	if (!currentUser) {
		return (
			<Navigate
				to="/signin"
				replace
			/>
		);
	}

	return children;
};

export default ProtectedRoutes;
