import React, { useState, useEffect } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { updateOrderStatus } from '../utils/api_call/api_calls';
import { Modal } from 'antd';
import PinField from 'react-pin-field';
import { toast } from 'sonner';
import Lottie from 'lottie-react';
import animationData from '../assets/Animation - 1712829832349.json';

const ActiveOrder = ({ currentUser, accessToken, refreshUser }) => {
	const [status, setStatus] = useState(
		currentUser?.assigned_order?.currentStatus || null,
	);
	const [PIN, setPIN] = useState('');
	const [openPINModal, setOpenPINModal] = useState(false);
	const [modalData, setModalData] = useState('');

	useEffect(() => {
		setStatus(currentUser?.assigned_order?.currentStatus || null);
	}, [currentUser?.assigned_order]);

	const handleSetOrderAsDelivered = async (order) => {
		try {
			const orderStatus = 'Delivered';
			const response = await updateOrderStatus(
				order._id,
				orderStatus,
				PIN,
				accessToken,
			);
			if (response.status === 200) {
				refreshUser();
				toast('Order Delivered');
				setStatus(response.data.currentStatus);
				setOpenPINModal(false);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleUpdateOrderStatus = async (order) => {
		try {
			let orderStatus;
			if (status === 'Ready for pickup') {
				orderStatus = 'Waiting for Courier';
			} else if (status === 'Waiting for Courier') {
				orderStatus = 'Picked Up';
			} else if (status === 'Picked Up') {
				if (order.authentication.usePIN) {
					setOpenPINModal(true);
					setModalData(order);
					return;
				} else {
					handleSetOrderAsDelivered(order);
					return;
				}
			}

			if (orderStatus) {
				const response = await updateOrderStatus(
					order._id,
					orderStatus,
					PIN,
					accessToken,
				);
				if (response.status === 200) {
					refreshUser();
					setStatus(response.data.currentStatus);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			{!currentUser?.assigned_order ? (
				<div className='bg-white rounded-md p-8 flex flex-col text-center justify-center items-center'>
					<Lottie
						className='h-48'
						animationData={animationData}
					/>
					<p className='w-2/3'>You have no assigned orders </p>
				</div>
			) : (
				<div className='bg-white rounded-md overflow-hidden'>
					<div className='bg-gray-900 text-white p-5'>
						<div className='flex w-full justify-between items-center'>
							<div>
								<p className='font-medium pb-2'>Active Order</p>
								<p>{currentUser?.assigned_order._id}</p>
							</div>
							<div className='text-sm'>{status}</div>
						</div>
						<div className='flex space-x-1.5 border-y border-gray-800 py-3 text-gray-400 text-sm'>
							<p>{currentUser?.assigned_order?.eta} minutes</p>
							<p>{currentUser?.assigned_order?.distance} km</p>
							<p>
								₦
								{
									currentUser?.assigned_order?.paymentDetails
										.deliveryCost
								}
							</p>
						</div>
					</div>
					<div className='space-y-7 p-5'>
						<div className='space-y-1'>
							<p className='text-sm text-gray-400'>Pickup from</p>
							<div className='flex justify-between items-center'>
								<div className='w-[90%]'>
									<p className='font-medium'>
										{
											currentUser?.assigned_order
												?.merchant?.name
										}
									</p>
									<p className='text-sm text-gray-600 w-full'>
										{
											currentUser?.assigned_order
												?.merchant?.location?.address
										}
									</p>
								</div>
								<div
									className='w-9 h-9 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer'
									onClick={() => {
										const phoneNumber =
											currentUser?.assigned_order
												?.merchant?.phone_number;
										if (phoneNumber) {
											const telLink = `tel:${phoneNumber}`;
											window.open(telLink);
										} else {
											console.error(
												'Merchant phone number not available',
											);
										}
									}}>
									<FaPhoneAlt size={12} />
								</div>
							</div>
						</div>
						<div className='space-y-1'>
							<p className='text-sm text-gray-400'>Deliver to</p>
							<div className='flex justify-between items-center'>
								<div className='w-[90%]'>
									<p className='font-medium'>
										{
											currentUser?.assigned_order
												?.customer?.name
										}
									</p>
									<p className='text-sm text-gray-600 w-full'>
										{
											currentUser?.assigned_order
												?.customer?.location?.address
										}
									</p>
								</div>
								<div
									className='w-9 h-9 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer'
									onClick={() => {
										const phoneNumber =
											currentUser?.assigned_order
												?.customer?.phone_number;
										if (phoneNumber) {
											const telLink = `tel:${phoneNumber}`;
											window.open(telLink);
										} else {
											console.error(
												'Customer phone number not available',
											);
										}
									}}>
									<FaPhoneAlt size={12} />
								</div>
							</div>
						</div>
						<div className='space-y-2'>
							<p className='text-sm text-gray-400'>Items</p>
							<div className='w-full flex flex-wrap text-sm'>
								{currentUser?.assigned_order?.products?.map(
									(e, index) => (
										<div
											key={index}
											className='border flex space-x-3 rounded-full px-2 py-1 text-gray-600 mr-2 mb-2 items-center'>
											<p>{e.product.name}</p>
											<div className='h-6 w-6 bg-gray-200 border rounded-full flex items-center justify-center text-sm font-semibold'>
												{e.quantity}
											</div>
										</div>
									),
								)}
							</div>
						</div>
						{status !== 'Delivered' &&
							status !== 'Received by Merchant' && (
								<button
									className='h-[48px] text-sm w-full bg-[#73e5bf] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center cursor-pointer flex items-center justify-center space-x-2'
									onClick={() =>
										handleUpdateOrderStatus(
											currentUser?.assigned_order,
										)
									}>
									<p>
										{status === 'Ready for pickup' &&
											'On the way'}
										{status === 'Waiting for Courier' &&
											'Order picked up'}
										{status === 'Picked Up' &&
											'Order Delivered'}
										{status === 'Returned' &&
											'Order Returned'}
									</p>
								</button>
							)}
					</div>
				</div>
			)}
			{openPINModal && (
				<Modal
					open={openPINModal}
					className='rounded-t-xl'
					onCancel={() => setOpenPINModal(false)}
					footer={null}
					closable={true}
					width={288}
					title={<div className='text-[24px]'>Input PIN</div>}>
					<div>
						<div className='flex space-x-4 items-center my-8'>
							<PinField
								length={4}
								onChange={(code) => setPIN(code)}
								className='h-[48px] text-center mx-auto w-[48px] flex border border-gray-300 rounded overflow-hidden'
							/>
						</div>
						<button
							className='h-[48px] text-sm w-full bg-[#73e5bf] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center cursor-pointer flex items-center justify-center space-x-2'
							onClick={() =>
								handleSetOrderAsDelivered(modalData)
							}>
							<p>Complete delivery</p>
						</button>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default ActiveOrder;
