import React from "react";

const Avatar = ({ name, imageSrc }) => {
  const initials = name && name.includes(" ") ? name.split(" ").map(word => word.charAt(0)).join('') : '';

  return (
    <div className="flex items-center justify-center h-[32px] w-[32px] rounded-full">
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={`${name}`}
          className="h-full w-full rounded-full object-cover"
        />
      ) : (
        <span className="text-[#333333] text-md font-semibold p-2">
          {initials}
        </span>
      )}
    </div>
  );
};

export default Avatar;
