export const debounce = (func, delay) => {
	let timeoutId;
	function debounced(...args) {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	}
	debounced.cancel = () => clearTimeout(timeoutId);
	return debounced;
};
