import React, { useEffect, useState } from 'react';
import { useAuth } from './utils/api_call/auth_context';
import Main from './pages/main';

const App = () => {
	const { currentUser, accessToken, refreshUser } = useAuth();
	const [ws, setWs] = useState(null);
	const [useDrawer, setUseDrawer] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const isTestEnv = process.env.REACT_APP_ENV === 'test';

	useEffect(() => {
		console.log('Establishing Websocket connection...');

		// Establish WebSocket connection
		const wsProtocol =
			window.location.protocol === 'https:' ? 'wss://' : 'ws://';
		// Use the appropriate host for WebSocket connection
		const host = isTestEnv
			? 'localhost:1222'
			: 'central-backend-e6ln.onrender.com';

		const socket = new WebSocket(`${wsProtocol}${host}`);

		setWs(socket);
		console.log(socket);

		socket.onopen = () => {
			console.log('WebSocket connected');
		};

		socket.onclose = () => {
			console.log('WebSocket disconnected');
		};
		// console.log(accessToken)

		return () => {
			if (socket) {
				socket.close();
			}
		};
	}, []);

	useEffect(() => {
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}

		if (screenWidth < 768) {
			setUseDrawer(true);
		} else {
			setUseDrawer(false);
		}

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [screenWidth]);

	return (
		<Main
			currentUser={currentUser}
			refreshUser={refreshUser}
			ws={ws}
			accessToken={accessToken}
			useDrawer={useDrawer}
		/>
	);
};

export default App;
