import { api } from './api_helper';

export const updateAvailability = async (currentUser, status, accessToken) => {
	try {
		const response = await api.patch(
			`/courier/availability/${currentUser._id}`,
			{
				status,
			},
			{
				withCredentials: true,
				headers: {
					jwt: accessToken,
				},
			},
		);

		return response.data;
	} catch (error) {
		// Handle errors
		console.error(error);
	}
};

export const updateOrderStatus = async (orderId, status, pin, accessToken) => {
	console.log(orderId, status, pin, accessToken);
	try {
		console.log('Order Status will be updated to: ', status);
		const response = await api.patch(
			`/order/${orderId}`,
			{
				status: status,
				pin: pin,
			},
			{
				withCredentials: true,
				headers: {
					jwt: accessToken,
				},
			},
		);

		console.log('At API Call: ', response.data);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const getBanksList = async () => {
	try {
		const response = await api.get('/payment/getbankslist', {
			withCredentials: true,
		});
		// console.log(response.data);
		return response.data;
	} catch (error) {}
};

export const addAccount = async (
	currentUser,
	bank_code,
	account_number,
	accessToken,
) => {
	console.log(
		'At add account, Current User: ',
		currentUser,
		'Access Token: ',
		accessToken,
	);
	const response = await api.patch(
		`/courier/update/${currentUser._id}`,
		{ bank_code: bank_code, account_number: account_number },
		{
			withCredentials: true,
			headers: {
				jwt: accessToken,
			},
		},
	);
	console.log(response);

	return response.data;
};

export const searchAddresses = async (address) => {
	try {
		const response = await api.post(
			'location/search',
			{
				searchInput: address,
			},
			{ withCredentials: true },
		);

		return response.data.addresses;
	} catch (error) {
		// Handle errors
		console.error(error);
	}
};

export const getCurrentUserLocation = async (latitude, longitude) => {
	try {
		const response = await api.get(`location/${longitude}/${latitude}`, {
			withCredentials: true,
		});

		return response.data;
	} catch (error) {
		// Handle errors more specifically if needed
		console.error('Error in getCurrentUserLocation API:', error.message);
		throw error; // Re-throw the error to propagate it to the caller
	}
};

export const updateLocation = async (currentUser, address, accessToken) => {
	try {
		console.log('Current User: ', currentUser, 'Address: ', address);
		const response = await api.patch(
			`/courier/update/${currentUser._id}`,
			{ address },
			{
				withCredentials: true,
				headers: {
					jwt: accessToken,
				},
			},
		);
		console.log(response);

		return response.data;
	} catch (error) {
		// Throw the error to propagate it to the caller
		console.error(error);
		throw error;
	}
};
