import React, { useState } from "react";

const ToggleButton = ({ onToggle, assigned }) => {
	// Set the initial state of the toggle button based on the courier's assignment status
	const [isToggled, setToggle] = useState(
		assigned === "Available"
			? true
			: assigned === "Unavailable"
			? false
			: assigned === "Assigned"
			? true
			: false
	);

	const handleToggle = () => {
		if (assigned !== "Assigned") {
			const newToggleState = !isToggled;
			setToggle(newToggleState);
			onToggle(newToggleState);
		} else {
			onToggle(true);
		}
	};

	return (
		<div
			className={`w-10 h-6 bg-gray-200 rounded-full relative flex items-center p-1 cursor-pointer ${
				assigned === "Assigned" ? "pointer-events-none" : ""
			}`}
			onClick={handleToggle}>
			<button
				className={`absolute left-1 w-4 h-4 rounded-full focus:outline-none transition-transform duration-300 transform ${
					isToggled ? "translate-x-full bg-green-700" : "bg-gray-400"
				}`}></button>
		</div>
	);
};

export default ToggleButton;
