import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../utils/api_call/auth_context';
import { Toaster, toast } from 'sonner';

const Signup = () => {
	const navigate = useNavigate();
	const [userName, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const { signupcourier } = useAuth();
	const [signUpLoading, setSignupLoading] = useState('');
	const signupActive = userName && phone && email && password;

	const handleSubmit = async () => {
		setSignupLoading(true);
		try {
			const response = await signupcourier(
				userName,
				phone,
				email,
				password,
			);
			// Handle successful signup
			if (response.status === 200) {
				navigate('/');
				setSignupLoading(false);
				toast.success(response.message);
			}
		} catch (error) {
			// Handle signup error
			console.error('Signup error:', error);
			setSignupLoading(false);
			toast.error(error.response.data.message);
		}
	};

	return (
		<div className='w-full flex items-center justify-center h-screen bg-gradient-to-tr from-[rgba(135,250,215,0.66)] via-[rgba(181,89,235,0.43)] to-[rgba(245,242,255,0.38)]'>
			<Toaster
				position='top-right'
				richColors
			/>
			<div className='w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0'>
				<div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
					<h1 className='text-xl font-bold leading-tight tracking-tight text-gray-400 md:text-2xl'>
						Create an account to continue
					</h1>
					<form
						className='space-y-4 md:space-y-6'
						action='#'>
						<div>
							<label
								htmlFor='Full Name'
								className='block mb-2 text-sm font-medium text-gray-400'>
								Full Name
							</label>
							<input
								placeholder='ex. Andre Smith'
								value={userName}
								required={true}
								onChange={(e) => setName(e.target.value)}
								type='name'
								name='name'
								id='name'
								className='bg-gray-50 border border-gray-400 text-gray-400 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
							/>
						</div>
						<div>
							<label
								htmlFor='phone'
								className='block mb-2 text-sm font-medium text-gray-400'>
								Phone Number
							</label>
							<input
								placeholder='08100000000'
								value={phone}
								required={true}
								onChange={(e) => setPhone(e.target.value)}
								type='phone'
								name='phone'
								id='phone'
								className='bg-gray-50 border border-gray-400 text-gray-400 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
							/>
						</div>
						<div>
							<label
								htmlFor='email'
								className='block mb-2 text-sm font-medium text-gray-400'>
								Email Address
							</label>
							<input
								placeholder='andre@smith.com'
								value={email}
								required={true}
								onChange={(e) => setEmail(e.target.value)}
								type='email'
								name='email'
								id='email'
								className='bg-gray-50 border border-gray-400 text-gray-400 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
							/>
						</div>
						<div>
							<label
								htmlFor='password'
								className='block mb-2 text-sm font-medium text-gray-400'>
								Password
							</label>
							<input
								placeholder='Password'
								value={password}
								required={true}
								onChange={(e) => setPassword(e.target.value)}
								type='password'
								name='password'
								id='password'
								className='bg-gray-50 border border-gray-400 text-gray-400 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
							/>
						</div>

						<button
							onClick={() => signupActive && handleSubmit()}
							type='button'
							className={`h-[48px] ${
								signupActive
									? 'bg-[#73e5bf]'
									: 'bg-gray-100 text-gray-400'
							} w-full focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
							{signUpLoading ? 'Loading...' : 'Create account'}
						</button>
						<p className='text-sm font-light text-gray-400'>
							Already Have an account?{' '}
							<span
								onClick={() => navigate('/signin')}
								className='font-medium text-primary-600 cursor-pointer'>
								Sign in
							</span>
						</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Signup;
