import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { paths } from "./utils/routes";
import App from "./App";
import Page404 from "./404";
import Signup from "./auth/signup";
import ProtectedRoutes from "./auth/protected_routes";
import SignIn from "./auth/signin";
import { AuthProvider } from "./utils/api_call/auth_context";

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<BrowserRouter basename="/">
			<AuthProvider>
				<Routes>
					<Route
						path={paths.HOME}
						element={
							<ProtectedRoutes>
								<App />
							</ProtectedRoutes>
						}
					/>
					<Route
						path={paths.SIGNUP}
						element={<Signup />}
					/>
					<Route
						path={paths.SIGNIN}
						element={<SignIn />}
					/>

					<Route
						path="*"
						element={<Page404 />}
					/>
				</Routes>
			</AuthProvider>
		</BrowserRouter>
	</React.StrictMode>
);
